"use strict";

export enum StateNames {
	BLUEPRINT_METRIC_STATE = "blueprintmeasuretype",
	SUN_DIAL_ENABLED_STATE = "sundialenabled",
	SUN_DIAL_VISIBLE_STATE = "sundialvisible",
	TYPING_STATE = "typing",
	TOUR_TAKEN = "tourTaken",
	BLUEPRINT_TIP_SEEN = "blueprintTipSeen",
	PRODUCT_TIP_SEEN = "productTipSeen",
	SYMBOL_TIP_SEEN = "symbolTipSeen",
	RESET_TOUR_AND_TIPS = "resetTourAndTips",
	BOARD_RADIAL_MENU = "designpalettemenu",
	CAPTURE_MODE = "capturemode",
	CAPTURE_MODE_BUTTON = "capturemodebtnvisible",
	PRODUCT_PLACER_ACTIVE = "productplaceractive",
	NOTIF_BANNER_ACTIVE = "notifbanner"
}
