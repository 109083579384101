"use strict";

import { EnsembleConfig } from "../../composer/ensemble/ensemble_config";
import { EnsembleEditor } from "../../composer/ensemble/ensemble_editor";
import { EnsembleUi } from "../../composer/ensemble/ensemble_ui";
import { CookieNames, CookieValues } from "../../enumerations/cookieNames";
import { StateNames } from "../../enumerations/stateNames";
import { StateValues } from "../../enumerations/stateValues";
import { TRACKING_VERSIONS } from "../../enumerations/trackingVersion";
import { IPlanConfig } from "../../interfaces/planConfig";
import { getCookie } from "../../utils/cookie-browser";

declare const CONFIG: EnsembleConfig;
declare const EDITOR: EnsembleEditor;
declare const UI: EnsembleUi;

export class EditorService {
	public static readonly PRODUCT_ACTIVE_STATE_TIMEOUT = 500;

	public static async getConfiguration(activeRequests?: XMLHttpRequest[]): Promise<any> {
		return CONFIG.getConfiguration(activeRequests).then((data: IPlanConfig) => {
			return {
				configuration: JSON.stringify(data),
				imageUrls: [data.thumbnail]
			};
		});
	}

	public static async getSnapshot(): Promise<string> {
		return await EDITOR.generateSnapshot(0);
	}

	public static async getThumbnail(): Promise<string> {
		return await EDITOR.generateSnapshot(1, 10);
	}

	public static getProductList() {
		return UI.getProductList();
	}

	public static SendTrackingEvent(category: string, action: string, name?: string, value?: number) {
		const planId = (window as any).config.planId;
		const userId = (window as any).config?.user?.["associate-id"];

		const isRedesignEnabled = getCookie(CookieNames.REDESIGN_ENABLED) === CookieValues.YES;
		const rpVersion = isRedesignEnabled ? TRACKING_VERSIONS.RP2 : TRACKING_VERSIONS.RP1;

		let countryCode = null;
		if ((window as any).config.user) {
			countryCode = (window as any).config.user.countryCode;
		}

		const args: any[] = ["trackEvent", countryCode, category];
		if (typeof name === "string") {
			args.push(`${action} - ${name}`);
		}
		if (typeof value === "number") {
			args.push(value);
		}

		if (!(window as any)._paq) {
			return;
		}
		// ID 1 and 2 are used for tracking Visit Dimensions and Action Dimensions for planId
		if (planId !== undefined) {
			(window as any)._paq.push(["setCustomDimension", 1, planId]);
			(window as any)._paq.push(["setCustomDimension", 2, planId]);
		}

		// ID 3 and 4 are used for userId
		if (userId !== undefined) {
			(window as any)._paq.push(["setCustomDimension", 3, userId]);
			(window as any)._paq.push(["setCustomDimension", 4, userId]);
		}

		// ID 5 and 6 are used for rpVersion
		(window as any)._paq.push(["setCustomDimension", 5, rpVersion]);
		(window as any)._paq.push(["setCustomDimension", 6, rpVersion]);
		(window as any)._paq.push(args);

		// Google Analytics
		const isGAEnabled = (window as any).config.isGoogleAnalyticsEnabled;
		if (category && isGAEnabled && Object.prototype.hasOwnProperty.call(window, "gtag")) {
			(window as any).gtag("event", "tracking", {
				category, action, name, value, planId, userId, rpVersion,
			});
		}
	}

	public static toggleTypingState(on: boolean) {
		const owComposer = (window as any).owComposer;
		if (!owComposer) {
			return;
		}

		owComposer.once(owComposer.EVENT.LOADED, () => {
			owComposer.state.set(StateNames.TYPING_STATE, on ? StateValues.ON : StateValues.OFF);
		});
	}

	public static toggleProductActiveState(on: boolean) {
		const owComposer = (window as any).owComposer;
		if (!owComposer) {
			return;
		}

		owComposer.once(owComposer.EVENT.LOADED, () => {
			owComposer.state.set(StateNames.PRODUCT_PLACER_ACTIVE, on ? StateValues.ON : StateValues.OFF);
		});
	}
}
