export enum CookieNames {
	COUNTRY_CODE = "countryCode",
	EXTERNAL_REFERER_COUNTRY_CODE = "extRefCountryCode",
	REGION_PROMPT_REDIRECTED = "regionPromptRedirected",
	REGION_PROMPT_REDIRECTED_YES = "YES",
	REGION_PROMPT_REDIRECTED_NO = "NO",
	REDESIGN_ENABLED = "REDESIGN_ENABLED"
}

export enum CookieValues {
	YES = "YES",
	NO = "NO"
}
