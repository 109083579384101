export enum Routes {
	PORTFOLIO_EXISTS_URL = "/portfolio/exists",
	PORTFOLIO_RENAME_URL = "/portfolio/create",
	PLAN_EXISTS_URL = "/plans/exists",
	PLAN_ID_EXISTS_URL = "/plans/id_exists",
	PLAN_RENAME_URL = "/plans/rename",
	PLAN_DEFAULT_URL = "/plans/default",
	SHARING_PDF_URL = "/sharing/pdf",
	SHARING_EMAIL_PDF_URL = "/sharing/emailpdf",
	SHARING_EMAIL_LINK_URL = "/sharing/emaillink",
	DATA_CREATE_PRODUCT_URL = "/data/create-product",
	CAPTURE_CAN_QUEUE_URL = "/capture/can-create",
	CAPTURE_CREATE_URL = "/capture/create",
	CAPTURE_MARK_READ_URL = "/capture/set-read",
	CAPTURE_GET_NEW_URL = "/capture/get-new",
	CAPTURE_GET_PENDING_URL = "/capture/get-pending",
	CAPTURE_GET_STATUS_URL = "/capture/get-status"
}

export enum XHR {
	CONTENT_TYPE_HEADER = "Content-Type",
	CONTENT_LENGTH_HEADER = "Content-Length",
	RESPONSE_ARRAY_BUFFER = "arraybuffer",
	APPLICATION_FORM_ENCODE_CONTENT_TYPE = "application/x-www-form-urlencoded",
	APPLICATION_JSON_CONTENT_TYPE = "application/json",
	APPLICATION_GRAPHQL_CONTENT_TYPE = "application/graphql",
	APPLICATION_PDF_CONTENT_TYPE = "application/pdf"
}

export enum Listeners {
	LOAD_EVENT = "load",
	TIMEOUT_EVENT = "timeout",
	ERROR_EVENT = "error",
	ABORT_EVENT = "abort",
	CLICK_EVENT = "click",
	SELECT_EVENT = "select"
}
