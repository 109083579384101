import { CookieNames } from "../enumerations/cookieNames";
import { CountryCode } from "../enumerations/countryCode";

export function getCookie(name: string): string | undefined {
	const matches = document.cookie.match(
		new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)")
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(name: string, value: string, options: {} = {}) {
	options = {
		path: "/",
		...options
	};

	// @ts-ignore
	if (options.expires instanceof Date) {
		// @ts-ignore
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = `${encodeURIComponent(name)} = ${encodeURIComponent(value)}`;

	Object.keys(options).forEach((optionKey) => {
		updatedCookie += `; ${optionKey}`;
		const optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += `=${optionValue}`;
		}
	});

	document.cookie = updatedCookie;
}

export function deleteCookie(name) {
	setCookie(name, "", {
		"max-age": -1
	});
	setCookie(`${name}.sig`, "", {
		"max-age": -1
	});
}

export function getPotentialCountryCodeCookie(): CountryCode | undefined {
	return getCookie(CookieNames.EXTERNAL_REFERER_COUNTRY_CODE) as CountryCode;
}

export function clearCountryCodeCookies() {
	deleteCookie(CookieNames.EXTERNAL_REFERER_COUNTRY_CODE);
	deleteCookie(CookieNames.COUNTRY_CODE);
}
