"use strict";

import Browser from "../utils/browser";
import { LoginForm } from "./controls/login";

let FRAME_RATE = 1 / 16;
let DRIFT_DURATION = 1.5; // time in seconds
let FADE_IN_TIMEOUT = 1300; // time in milliseconds;

window.addEventListener("load", () => {
	const config = (window as any).config;

	if (config.isGuestMode) {
		FRAME_RATE = 1 / 19;
		DRIFT_DURATION = 1.19;
		FADE_IN_TIMEOUT = 1300;
	}

	const form = document.querySelector(".login-form") as HTMLFormElement;
	const loginForm = new LoginForm(form);

	if (!config.simpleLogin) {
		startLogoAnimation(loginForm);
		listenForSignInAgain(loginForm);
	}

	const togglePassword = document.getElementById("passwordToggle") as HTMLElement;
	const passwordInput = document.getElementById("password") as HTMLInputElement;

	if (togglePassword && passwordInput) {
		const handlePasswordtoggle = () => {
			if (passwordInput.type === "password") {
				passwordInput.type = "text";
				togglePassword.classList.remove("fa-eye-slash");
				togglePassword.classList.add("fa-eye");
			} else {
				passwordInput.type = "password";
				togglePassword.classList.remove("fa-eye");
				togglePassword.classList.add("fa-eye-slash");
			}
		};
		togglePassword.addEventListener("click", handlePasswordtoggle);
	}

	const redesignedContainerOfLoginPage = document.querySelector(".guest-login-container-redesigned");
	if (redesignedContainerOfLoginPage && Browser.isSafari() && !Browser.isChrome()) {
		redesignedContainerOfLoginPage.classList.add("safari");
	}
});

function startLogoAnimation(loginForm: LoginForm) {
	const config = (window as any).config;
	const staticLogo = document.querySelector(".login-logo") as HTMLImageElement;
	const floatingLogo = document.querySelector(".floating-logo") as HTMLImageElement;
	const blurredBackground = document.querySelector(".splash-background.blurred") as HTMLElement;

	if (!staticLogo || !floatingLogo) {
		return;
	}

	let startY = floatingLogo.getBoundingClientRect().top;
	let endY = staticLogo.getBoundingClientRect().top + 1;

	if (config.isGuestMode) {
		startY = 0;
		endY = staticLogo.getBoundingClientRect().top - 119;
	}

	floatingLogo.classList.add("visible");

	setTimeout(() => {
		driftLogo(floatingLogo, startY, endY, loginForm.show.bind(loginForm));
		if (blurredBackground) {
			blurredBackground.classList.add("visible");
		}
	}, FADE_IN_TIMEOUT);

	window.addEventListener("resize", () => {
		if (config.isGuestMode) {
			floatingLogo.style.top = `${staticLogo.getBoundingClientRect().top - 119}px`;
		} else {
			floatingLogo.style.top = `${staticLogo.getBoundingClientRect().top}px`;
		}
	});
}

function driftLogo(logoElement: HTMLElement, startYPosition: number, endYPosition: number, driftFinishCB: () => any) {
	const distance = endYPosition - startYPosition;
	const half = 1 / 2;
	const one = 1;
	const negOne = -1;
	let currentTime = 0;

	const easeInOutSine = (progress: number) => {
		return negOne * half * (Math.cos(Math.PI * progress) - one);
	};

	const float = () => {
		currentTime += FRAME_RATE;
		const progress = currentTime / DRIFT_DURATION;

		if (progress < 1) {
			const position = startYPosition + distance * easeInOutSine(progress);
			logoElement.style.top = `${position}px`;
			requestAnimationFrame(float);
		} else {
			logoElement.style.top = `${endYPosition}px`;
			driftFinishCB();
		}
	};
	logoElement.style.top = `${startYPosition}px`;
	requestAnimationFrame(float);
}

function listenForSignInAgain(loginForm: LoginForm) {
	const loggedOutWrapper = (document.querySelector(".logged-out-wrapper") ||
		document.querySelector(".logout-control")) as HTMLElement;
	const signInAgainButton = document.querySelector(".sign-in-again") as HTMLButtonElement;
	const blurredBackground = document.querySelector(".splash-background.blurred") as HTMLElement;

	signInAgainButton.addEventListener("click", () => {
		loggedOutWrapper.classList.remove("visible");
		if (blurredBackground) {
			blurredBackground.classList.add("visible");
		}
		loginForm.show();
	});
}
