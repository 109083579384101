"use strict";

import { XHR } from "../../enumerations/web";
import { EditorService } from "./editorService";

const ERROR_SELECTOR = ".error";
const HTTP_OKAY_RESPONSE_CODE = 200;
const DEFAULT_SIGN_IN_STATUS = "sign in";
const DEFAULT_GUEST_SIGN_IN_STATUS = "continue as guest";
const SIGNING_IN_STATUS = "signing into room planner...";
const GUEST_SIGNING_IN_STATUS = "continuing as guest...";
const SIMPLE_SIGNING_IN_STATUS = "signing in...";

export class LoginForm {
	private loginControl: HTMLFormElement;
	private validEmail: boolean;
	private validPassword: boolean;

	public constructor(form: HTMLFormElement) {
		if (!form) {
			throw new Error("Could not find login form");
		}

		this.loginControl = form;

		this.validEmail = false;
		this.validPassword = false;

		this.listenForLogin();
	}

	public show() {
		this.loginControl.classList.add("visible");
	}

	public setButtonText(button: HTMLButtonElement, message: string) {
		button.innerText = message;
	}

	private listenForLogin() {
		const email = this.loginControl.querySelector("#email") as HTMLInputElement;
		const password = this.loginControl.querySelector("#password") as HTMLInputElement;
		const loginButton = this.loginControl.querySelector(".login-button") as HTMLButtonElement;
		const guestButton = this.loginControl.querySelector(".guest-button") as HTMLButtonElement;

		if (!email) {
			throw new Error("Could not find email field");
		}

		if (!password) {
			throw new Error("Could not find password field");
		}

		this.listenForInput(email, "email");
		this.listenForInput(password, "password");

		this.loginControl.addEventListener("submit", (event) => {
			event.preventDefault();

			const config = (window as any).config;

			this.validateEmail(email.value);
			this.validatePassword(password.value);

			if (this.validEmail && this.validPassword) {
				if (loginButton) {
					this.setButtonText(loginButton, config.simpleLogin ? SIMPLE_SIGNING_IN_STATUS : SIGNING_IN_STATUS);
				}
				this.toggleError("login-button", false);
				this.loginWithCredentials(email.value, password.value);
			}
		});

		if (guestButton) {
			guestButton.addEventListener("click", (event) => {
				if (guestButton) {
					this.setButtonText(guestButton, GUEST_SIGNING_IN_STATUS);
				}
				this.toggleError("login-button", false);
				this.loginWithCredentials("guest", "");
			});
		}
	}

	private listenForInput(element: HTMLElement, errorForSelector: string) {
		element.addEventListener("input", () => {
			this.toggleError(errorForSelector, false);
			this.toggleError("login-button", false);
		});
	}

	private validateEmail(email: string) {
		if (this.isInvalidEmail(email)) {
			this.validEmail = false;
			this.toggleError("email", true);
		} else {
			this.validEmail = true;
		}
	}

	private validatePassword(password: string) {
		if (this.inputEmpty(password)) {
			this.validPassword = false;
			this.toggleError("password", true);
		} else {
			this.validPassword = true;
		}
	}

	private isInvalidEmail(emailString: string) {
		// tslint:disable-next-line:max-line-length
		return !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			emailString
		);
	}

	private inputEmpty(value: string) {
		return /^\s*$/.test(value);
	}

	private toggleError(errorForSelector: string, show: boolean) {
		const error = this.loginControl.querySelector(`${ERROR_SELECTOR}[for="${errorForSelector}"]`);
		if (!error) {
			return;
		}
		error.classList.toggle("visible", show);
	}

	private isMobileDevice() {
		if (typeof window?.matchMedia !== "function") {
			return false;
		}

		const isMobileInPortraitMode = window?.matchMedia(
			"(max-width: 450px) and (max-height: 940px) and (orientation: portrait)"
		).matches;

		const isMobileInLandscapeMode = window?.matchMedia(
			"(max-width: 940px) and (max-height: 450px) and (orientation: landscape)"
		).matches;

		return isMobileInPortraitMode || isMobileInLandscapeMode;
	}

	private loginWithCredentials(email: string, pass: string) {
		const config = (window as any).config;

		const loginButton = this.loginControl.querySelector(".login-button") as HTMLButtonElement;
		const guestButton = this.loginControl.querySelector(".guest-button") as HTMLButtonElement;
		const xhr = new XMLHttpRequest();

		let route = "/auth/creds";
		if (config.simpleLogin) {
			route = "/auth/simple";
		}
		xhr.open("POST", route);
		xhr.setRequestHeader(XHR.CONTENT_TYPE_HEADER, XHR.APPLICATION_JSON_CONTENT_TYPE);
		xhr.addEventListener("load", () => {
			if (xhr.status !== HTTP_OKAY_RESPONSE_CODE) {
				const errorMessage = "Login with credentials issues";
				EditorService.SendTrackingEvent("ERROR", errorMessage, "loginWithCredentials");
				this.toggleError("login-button", true);
				if (loginButton) {
					this.setButtonText(loginButton, DEFAULT_SIGN_IN_STATUS);
				}
				if (guestButton) {
					this.setButtonText(guestButton, DEFAULT_GUEST_SIGN_IN_STATUS);
				}
			} else {
				if (this.isMobileDevice()) {
					EditorService.SendTrackingEvent("Mobile", "Splash Page", "Log In");
				}

				window.location.assign("/ensemble");
			}
		});
		xhr.send(
			JSON.stringify({
				email: `${email}`,
				pass: `${pass}`
			})
		);
	}
}
